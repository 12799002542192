.explorer{
  height: 100%;
  width: 100%;
}
.exploreschemes{
  background-color: #f6f8f9;
  /* width: 100%;
  min-width: 780px; */
}

.exploreschemes .scheme-container {
  position: unset;
}

.exploreschemes .MuiTypography-root {
  position: relative;
}

#exploreschemespic {
  height: 380px; /* Let the image adjust its height proportionally */
  mix-blend-mode: multiply;
  opacity: 1;
  width: 900px !important;
  margin-top: -120px;
}

#idpschemespic {
  height: 360px;
  mix-blend-mode: multiply;
  position: relative;
  top: -73px;
  right: 10%;
  width: 800px;
  opacity: 1;
}

#lessgreater {
  width: 10px;
  height: 10px;
}

.contactUsImg {
  object-fit: cover;
}


#binoculars {
  height: 386px;
  position: absolute;
  mix-blend-mode: multiply;
  top: 140px;
  right: 10%;
  width: 570px;
}
.setExplorer{
  /* margin-top: -110px; */
}
#contactuspic {
  height: 350px; /* Let the image adjust its height proportionally */
  mix-blend-mode: multiply;
  opacity: 1;
  width: 900px !important;
  margin-top: -90px;
}

.exploreschemes ul {
  list-style: disc;
}

.scheme-accordion {
  background-color: #EAECF0;
  border: 1px solid #A6ADB480;
  box-shadow: none;
}

.idp-vendors-table {
  width: 100%;
  border-top: 1px solid #DDE1E6;
  border-left: 1px solid #DDE1E6;
}

.idp-vendors-table > div {
  height: 50px;
  border-bottom: 1px solid #DDE1E6;
  border-right: 1px solid #DDE1E6;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.clickContactText{
  color: #0065bd;
  text-decoration: none;
  cursor:pointer !important;
}

.clickContactText:hover {
  text-decoration: underline;
  color: #0065bd
}

.overlap-banner {
  margin-top: -20px !important;
}

.bannerplace{
  position: unset;
  height: 250px;
  background-color: #f6f8f9;
}

.banner{
  position: unset;
  top: unset;
}

.schemewht{
  margin-top: -85px;
}
.schememsi{
  margin-top: -175px;
}

@media (max-width: 600px) {
  .schemewht, .schememsi{
    margin-top: 0px;
  }

}
@media only screen and (min-width: 1090px) {
  .resp1090 {width: 100%;}
}

@media only screen and (min-width: 1920px) {
  .exploreschemes {  min-width: unset;}

  #exploreschemespic {
    height: 380px; /* Let the image adjust its height proportionally */
    mix-blend-mode: multiply;
    opacity: 1;
    width: 900px !important;
    margin-top: -120px;
  }

  #idpschemespic {
    height: 360px;
    mix-blend-mode: multiply;
    position: relative;
    top: -73px;
    right: -41%;
    width: 800px;
    opacity: 1;
  }
}


